.is-disabled {
  pointer-events: none;
  opacity: 0.5;
}
.is-disabled input {
  pointer-events: none;
}
.is-disabled label {
  pointer-events: none;
}
.is-disabled select {
  pointer-events: none;
}
