div.address-autocomplete ul.c-list-ui {
    border-top: none;
  }
  
  div.address-autocomplete li.c-list-ui__item {
    padding: 7px 15px;
    cursor: pointer;
  }
  
  div.address-autocomplete .o-fieldset__row:not(:last-child) {
    margin-bottom: 0 !important;
  }
  